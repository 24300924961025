import React from 'react';

import Header from './components/header';
import Hero from './components/hero';
import Sale from './components/sale';
import Market from './components/market';
import PlayToEarn from './components/play-to-earn';
import Training from './components/training';
import SportBags from './components/sport-bags';
import GameCards from './components/game-cards';
import Partnership from './components/partnership';
import Timeline from './components/timeline';
import Footer from './components/footer';

const Layout = () => {
  return (
    <>
      <Header />
      <Hero />
      <Sale />
      <Market />
      <PlayToEarn />
      <Training />
      <SportBags />
      <GameCards />
      <Partnership />
      <Timeline />
      <Footer />
    </>
  );
}

export default Layout;
